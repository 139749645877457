import "lazysizes"
import "picturefill"
// var $ = require('jQuery');

//アコーディオン
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

//ヘッダーメニュー
$('.header__spBtn').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('.header__top').removeClass('is-active');
    $('.header__spMenu').slideUp();
  } else {
    $(this).addClass('is-active');
    $('.header__top').addClass('is-active');
    $('.header__spMenu').slideDown();
  }
})

// ヘッダー背景
$(window).scroll(function() {
  var scroll = $(this).scrollTop();
  if(scroll > 100) {
    $(".header__top").addClass('bg-active');
  }else {
    $(".header__top").removeClass('bg-active');
  }
});

// 喜びの声
$('.topVoice__bubbleSlide').slick({
  dots: false,
  arrows: false,
  slidesToShow: 1,
  fade: true,
  asNavFor: '.topVoice__mainSlide'
});

$('.topVoice__mainSlide').slick({
  prevArrow: `<div class="slide-arrow prev-arrow"><img src="${path}/img/common/slick_prev.svg" alt=""></div>`,
  nextArrow: `<div class="slide-arrow next-arrow"><img src="${path}/img/common/slick_next.svg" alt=""></div>`,
  dots: false,
  arrows: true,
  slidesToShow: 1,
  centerMode: true,
  asNavFor: '.topVoice__bubbleSlide',
  centerPadding:'385px',
  responsive: [{
    breakpoint: 835,
    settings: {
      centerPadding: "72px",
    }
  }]  
});

$('.topVoice__item').on('click', function () {
  const index = $(this).index();
  $('.topVoice__mainSlide').slick("slickGoTo", index - 2, false)
})

// スクロール処理
$(window).scroll(function () {
  // スクロール位置取得
  const scroll = $(this).scrollTop();
  // ターゲット取得
  const targetElement = document.getElementsByClassName('shape');
  // 画面高さ取得
  const screenHeight = window.outerHeight 

  // 固定CTA
  if(scroll > 700) {
    $(".topFixedCta--pc").addClass('is-active');
  } else {
    $(".topFixedCta--pc").removeClass('is-active');
  }
  if(scroll > 400) {
    $(".topFixedCta--sp").addClass('is-active');
  } else {
    $(".topFixedCta--sp").removeClass('is-active');
  }  

  for (const elem of targetElement) {
    // ページ最上部からの位置取得
    const clientRect = elem.getBoundingClientRect();
    const py = window.pageYOffset + clientRect.top;
    const active = py - scroll - screenHeight
    if(0 > active) {
      // elem.style.transform = `translateY(${active / 10}px)`
      elem.style.backgroundPosition = `0 ${active / 10 + 200}px`
    }
  }  

  const topServiceWhy = document.getElementsByClassName('topServiceWhy');

  for (const elem of topServiceWhy) {
    // ページ最上部からの位置取得
    const clientRect = elem.getBoundingClientRect();
    const py = window.pageYOffset + clientRect.top;
    const active = py - scroll - screenHeight
    if(0 > active) {
      // elem.style.transform = `translateY(${active / 10}px)`
      elem.style.backgroundPosition = `0 ${active / 10 + 100}px`
    }
  }    

  const spFlag = window.matchMedia && window.matchMedia('(max-device-width: 834px)').matches  

  // ご利用の流れ
  const lineElement = document.getElementsByClassName('topFlow__line');
  const clientRect = lineElement[0].getBoundingClientRect();
  const py = window.pageYOffset + clientRect.top;
  const active = py - scroll - screenHeight
  if(0 > active) {
    if(spFlag) {
      lineElement[0].style.height = `${Math.abs(active) - 400}px` 
    } else {
      lineElement[0].style.height = `${Math.abs(active) - 700}px` 
    }
  }  

  const numElement = document.getElementsByClassName('topFlow__item__num');

  for (const elem of numElement) {
    const clientRect = elem.getBoundingClientRect();
    const py = window.pageYOffset + clientRect.top;
    const active = py - scroll - screenHeight
    if(spFlag) {
      if(-400 > active) {
        $(elem).addClass('active');
      }else {
        $(elem).removeClass('active');
      }
    } else {
      if(-700 > active) {
        $(elem).addClass('active');
      }else {
        $(elem).removeClass('active');
      }
    }
  }    

  // MVシェイプ
  // const mvElement = document.getElementsByClassName('shapeMv');
  // for (const elem of mvElement) {
  //   elem.style.transform = `translateY(${-scroll / 5}px)`
  // }  
});